import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingPartsRodService {
  public baseUrl: string = environment.apiBaseUrl + '/trackingPartsROD';

  constructor(public httpClient: HttpClient) {}

  getVendorDetailsRODBypartType(parts) {
    return this.httpClient.put(
      this.baseUrl + '/getVendorDetailsBypartType',
      parts
    );
  }

  getTrackingPartRODDetailsSearch(parts) {
    return this.httpClient.put(
      this.baseUrl + '/getTrackingPartDetailsSearch',
      parts
    );
  }
  getTrackingPartRODDetailsByStockNo(parts) {
    return this.httpClient.put(
      this.baseUrl + '/getTrackingPartDetailsByStockNo',
      parts
    );
  }

  updateTrackingPartsROD(parts) {
    return this.httpClient.put(this.baseUrl + '/updateTrackingPartsROD', parts);
  }
  updateTrackingPartsRODScanOut(parts) {
    return this.httpClient.put(
      this.baseUrl + '/updateTrackingPartsRODScanOut',
      parts
    );
  }

  getMaterialCodeBasedOnVendorWo(vendorWO) {
    return this.httpClient.post(this.baseUrl + '/getMaterialCode', vendorWO);
  }

  updateTrackingPartsRodDetails(data) {
    return this.httpClient.post(this.baseUrl + '/update', data);
  }

  getRodDetailsbyMaterialCodeAndStatus(data) {
    return this.httpClient.post(this.baseUrl + '/byMaterialCode', data);
  }

  getRodDetailsByStockNo(stockNo) {
    const params = new HttpParams().set('stockNo', stockNo);
    return this.httpClient.get(this.baseUrl + '/byStockNo', { params });
  }

  getRODScrapDetails(status: any) {
    return this.httpClient.put(this.baseUrl + '/getRODScrapDetails', status);
  }

  getDetailsBasedOnStockNo(status: any) {
    return this.httpClient.put(
      this.baseUrl + '/getDetailsBasedOnStockNo',
      status
    );
  }

  updateRODDetails(data) {
    return this.httpClient.put(this.baseUrl + '/updateRODDetails', data);
  }

  returnFromRejectionStatusUpdation(data) {
    return this.httpClient.post(this.baseUrl + '/returnFromRejection', data);
  }

  getTrackingPartRODByStatus(data) {
    return this.httpClient.post(this.baseUrl + '/byStatus', data);
  }

  getTrackingRODCompletedByStatus(data) {
    return this.httpClient.post(this.baseUrl + '/rodQCSearch', data);
  }

  getRODQCpendingReport(data) {
    return this.httpClient.post(this.baseUrl + '/rodSearch', data);
  }
  updateROD(data) {
    return this.httpClient.put(this.baseUrl + '/diaUpdate', data);
  }
  saveLength(data) {
    return this.httpClient.put(this.baseUrl + '/updateParts', data);
  }
}
