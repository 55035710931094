export class BoughtOutMaster {
    _id: string;
    partNameId: any;
    partName: string;
    partNameInShort: string;
    materialUsageType: string;
    materialUsageTypeId: string;
    internalPartCode: string;
    subCategoryId: any;
    subCategoryName: string;
    subCategoryNo: number;
    status: string;
    materialGradeId: any;
    materialId: string;
    materialGradeCode: string;
    materialUsage: string;
    unitType: string;
    unit: number;
    weight: number;
    activeStatusId: string;
    statusDescription: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
}
