export class Rejection {
    _id: number;
    stockNo: number;
    vendorName: string;
    vendorCode: string;
    vendorWO: any;
    subContractVendorName: string;
    subContractWO: any;
    pumpModel: string;
    partName: string;
    partType: string;
    materialId: string;
    internalPartCode: string;
    vendorPartCode: string;
    weight: number;
    quantity: number;
    bulkCheck: number;
    returnCost: number;
    actionToBeTaken: string;
    percentage: number;
    cost: number;
    reason: string;
    remarks: string;
    status: any;
    rejectedBy: string;
    rejectedDate: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
    document: string;
    fromDate: string;
    toDate: string ;
    materialCode: string;
    length: number;
    materialUsage: string;
    vendorType: string;
    invoiceNO: string;
    subContractDcNo: string;
    vendor: any;
    finalAmount: number;
    gstAmount: number;
    gstPercentage: number;
    totalAmount: number;
    removalDate: string;
    invoiceDate: string;
    partDetails: any[];
}
