import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RejectionService {
  public baseUrl: string = environment.apiBaseUrl + '/rejection';

  constructor(public httpClient: HttpClient) { }

  saveRejection(parts) {
    return this.httpClient.post(this.baseUrl + '/saveRejection', parts);
  }

  saveRejectionAssemblyQC(parts) {
    return this.httpClient.post(this.baseUrl + '/saveRejectionAssemblyQC', parts);
  }

  saveRejectionRODYard(parts) {
    return this.httpClient.post(this.baseUrl + '/saveRejectionRODYard', parts);
  }

  saveBORejection(parts) {
    return this.httpClient.post(this.baseUrl + '/saveBORejection', parts);
  }

  getRejectionDetailsByVendorTypeAndStatus(query){
    return this.httpClient.post(this.baseUrl+ '/byStatusAndType',query);
  }

  getPartWiseSumQuantity(data) {
    return this.httpClient.post(this.baseUrl + '/getPartWiseSumQty', data );
  }

  updateReturn(data: any) {
    return this.httpClient.patch(this.baseUrl + '/updateReturn', data);
  }
  getInvoice() {
    return this.httpClient.get(this.baseUrl + '/getInvoiceNo' );
  }

  generateDebitNote(data: any) {
    return this.httpClient.post(this.baseUrl + '/debitNote', data, {
      responseType: 'arraybuffer'
    });
  }


  updateRejection(data: any) {
    return this.httpClient.patch(this.baseUrl + '/updateRejection', data,
    );
  }

  getStockNoWiseDataForRejection(data) {
    return this.httpClient.post(this.baseUrl + '/getStockNoWiseDataForRejection', data);
  }

  getRejectionFormInvoice() {
    return this.httpClient.get(this.baseUrl + '/getRejectionFromInvoiceNo');
  }

  getInvoiceDetails(data) {
    return this.httpClient.put(this.baseUrl + '/getInvoiceDetails', data);
  }

  saveRejectionFinalInvoice(data) {
    return this.httpClient.post(this.baseUrl + '/saveRejectionFinalInvoice', data);
  }

}
