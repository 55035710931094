import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  public baseUrl = environment.apiBaseUrl + '/customers';
  constructor(private http: HttpClient) {}

  getActiveCustomer = () => {
    return this.http.get(this.baseUrl + '/active');
  };

  saveCustomer = (customer) => {
    return this.http.post(this.baseUrl + '/save', customer);
  };

  getAllCustomer = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  updateCustomer = (customer) => {
    return this.http.patch(this.baseUrl + '/update', customer);
  };

  deleteCustomer = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };

  updateCustomerStatus = (customer) => {
    return this.http.put(this.baseUrl + '/status', customer);
  };

  getCustomerById(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get(this.baseUrl + '/customer', { params });
  }

  savePumpCustomer(data) {
    return this.http.post(this.baseUrl + '/customerpump', data);
  }
  getAllCustomerPumpMasters() {
    return this.http.get(this.baseUrl + '/getAllPumpMasters');
  }
  updatePackingTypeStatus(data) {
    return this.http.put(this.baseUrl + '/statusPump', data);
  }
  deletePackingTypeMaster(id) {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/deletePump', { params });
  }
  getAllCustomerByGroup(data) {
    return this.http.put(this.baseUrl + '/getAllCustomerByGroup', data);
  }
}
