export class PartMaster {
    _id: string;
    partName: string;
    partNo: string;
    status: number;
    activeStatusId: string;
    materialUsageTypeId: any;
    materialUsage: string;
    statusDescription: string;
    machiningProcess: number;
    partSize: number;
    partCodeType: string;
    partShortName: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
}
