import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  public baseUrl: string = environment.apiBaseUrl + '/purchases';
  constructor(private httpClient: HttpClient) {}

  getVendorWO(vendorId, vendorCode) {
    const params = new HttpParams()
      .set('vendorId', vendorId)
      .set('vendorCode', vendorCode);
    return this.httpClient.get(this.baseUrl + '/getVendorWO', { params });
  }

  savePurchase(purchase) {
    return this.httpClient.post(`${this.baseUrl}/savePurchase`, purchase, {
      responseType: 'arraybuffer',
    });
  }

  OrderUpdatePurchaseCasting(purchase) {
    return this.httpClient.post(
      `${this.baseUrl}/OrderUpdatePurchaseCasting`,
      purchase
    );
  }

  OrderUpdatePurchaseROD(purchase) {
    return this.httpClient.post(
      `${this.baseUrl}/OrderUpdatePurchaseROD`,
      purchase
    );
  }

  getAllPurchaseForUpdate = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/getAll`, data);
  };

  getPurchasePartByVendorWO = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/byVendorWO`, data);
  };

  updatePurchaseCasting = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/updateCasting`, data, {
      responseType: 'arraybuffer',
    });
  };

  deletePurchasePartDetails = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/deleteCasting`, data);
  };

  cancelVendorWO = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/cancel`, data);
  };
  getVendorNameByStatus(details) {
    return this.httpClient.put(
      `${this.baseUrl}/getVendorNameByStatus`,
      details
    );
  }

  updatePurchaseROD = (data) => {
    return this.httpClient.patch(`${this.baseUrl}/updateROD`, data, {
      responseType: 'arraybuffer',
    });
  };

  updatePurchaseBO(details) {
    return this.httpClient.put(`${this.baseUrl}/updateBO`, details, {
      responseType: 'arraybuffer',
    });
  }

  printCasting(data) {
    return this.httpClient.put(`${this.baseUrl}/printCating`, data, {
      responseType: 'arraybuffer',
    });
  }
  printRod(data) {
    return this.httpClient.put(`${this.baseUrl}/printRod`, data, {
      responseType: 'arraybuffer',
    });
  }
  getAllPendingCasting() {
    return this.httpClient.get(`${this.baseUrl}/pendingCasting`);
  }
  getPendingVendors() {
    return this.httpClient.get(`${this.baseUrl}/pendingVendor`);
  }
  completedVendorReport() {
    return this.httpClient.get(`${this.baseUrl}/completedVendorReport`);
  }
  searchPuchaseCasting(data) {
    return this.httpClient.put(`${this.baseUrl}/searchPuchaseCasting`, data);
  }
  completedCastingReport() {
    return this.httpClient.get(`${this.baseUrl}/completedCasting`);
  }
}
