import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsigneeService {

  constructor( private http: HttpClient) { }

  public baseUrl = environment.apiBaseUrl + '/consignee';

  saveConsignee(data){
    return this.http.post(this.baseUrl + '/save',data );
  }
  getAllConsignee(){
    return this.http.get(this.baseUrl+'/getAll')
  }
  updateConsigneeStatus(consignee){
    return this.http.put(this.baseUrl+"/status",consignee)
  }
  updateConsignee(consignee){
    return this.http.patch(this.baseUrl+'/update',consignee)
  }
  deleteConsignee(id){
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl+'/delete',{params})
  }

}
