export class BoughtOutQc {
    _id: string;
    vendorWO: number;
    vendorId: string;
    partNameId: any;
    materialGradeId: any;
    subCategoryId: any;
    partName: string;
    vendorName: string;
    internalPartCode: string;
    internalPartCodeId: number;
    weight: number;
    invoiceNo: string;
    length: number;
    unit: string;
    quantity: number;
    receivedWeight: number;
    receivedLength: number;
    receivedQuantity: number;
    rejectedUnit: number;
    reworkedUnit: number;
    stockUnit: number;
    verifiedUnit: number;
    status: string;
    materialName:string;
    fromDate: string;
    toDate: string;
}
