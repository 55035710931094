import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ItemMaster } from '../model/item-master';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemMasterService {

  public baseURL: string =  environment.apiBaseUrl + '/itemMasters';
  public itemMaster: ItemMaster[];

  constructor(private http: HttpClient) { }
  getAllItemMaster = () =>  {
    return this.http.get(this.baseURL + '/getAll');
  }

  saveItemMaster = (itemMaster: ItemMaster) => {
    return this.http.post(this.baseURL + '/save', itemMaster);
  }

  updateItemMaster = (itemMaster: ItemMaster) => {
    return this.http.patch(this.baseURL + '/update', itemMaster);
  }

  getActiveItemMaster = () => {
    return this.http.get(this.baseURL + '/active');
  }

  deleteItemMaster  = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseURL + '/delete', { params });
  }

  updateItemMasterStatus = (itemMaster: ItemMaster) => {
    return this.http.put(this.baseURL + '/status', itemMaster);
  }

  getItemNameInCategoryId(data) {
    return this.http.post(this.baseURL + '/itemName', data);
  }
}
