import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class MainDesignService {
  public baseUrl = environment.apiBaseUrl + "/mainDesigns";

  constructor(private http: HttpClient) {}

  saveMainDesign(design) {
    return this.http.post(this.baseUrl + "/", design);
  }
  getAllDesign() {
    return this.http.get(this.baseUrl + "/");
  }
  searchDesign(design) {
    return this.http.put(this.baseUrl + "/searchDesign", design);
  }
  searchDesignResult(design) {
    return this.http.put(this.baseUrl + "/searchDesignResult", design);
  }
}
