export class OrderReport {
        _id: string;
        customerName: string;
        customerPO: string;
        internalWO: string;
        customerPODate: string;
        moc: string;
        deliveryDate: string;
        orderDate: string;
        orderPartId: string;
        pumpModel: string;
        partName: string;
        weight: number;
        orderType: string;
        externalPartCode: string;
        internalPartCode: string;
        quantity: number;
        impDiameter:number;
        impDia:number;
        impDia1:number;
        deliveredQuantity: number;
        balanceQuantity: number;
        status: string;
        lastDeliveryDate: string;
        packingType:string;
        lubricationType:string;
        fromDate: string;
        toDate: string;
        document: string;
        partType: string;
        materialId: string;
        subCategoryDescription: string;
        remarks:string
}
