import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class PatternPuchaseService {
  public baseUrl = environment.apiBaseUrl + "/patternPurchase";
  constructor(private http: HttpClient) {}

  savePuchasePattern(master) {
    return this.http.post(this.baseUrl + "/", master, {
      responseType: "arraybuffer"
    });
  }
  getVendorWO(vendorId, vendorCode) {
    const params = new HttpParams()
      .set("vendorId", vendorId)
      .set("vendorCode", vendorCode);
    return this.http.get(this.baseUrl + "/getVendorWO", { params });
  }
  getAllPendingData(data) {
    return this.http.put(this.baseUrl + "/getpendingData", data);
  }
  getsearchDetails(data) {
    return this.http.put(this.baseUrl + "/getsearchDetails", data);
  }
  savePatternGate(data) {
    return this.http.post(this.baseUrl + "/savePatternGate", data);
  }
}
