import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialPurchaseMasterService {

  public baseUrl: string = environment.apiBaseUrl + '/materialPurchaseMaster';
  constructor(private http: HttpClient) { }
  getAllMaterialPurchaseMasters() {
    return this.http.get(this.baseUrl + '/getAll');
}

getActiveMaterialPurchaseMasters() {
    return this.http.get(this.baseUrl + '/active');

}
saveMaterialPurchaseMasters(purchasePdfMaster) {
    return this.http.post(this.baseUrl + '/save', purchasePdfMaster, );

}

updateMaterialPurchaseMasters(purchasePdfMaster) {
    return this.http.patch(this.baseUrl + '/update', purchasePdfMaster);
}

deleteMaterialPurchaseMasters  = (id) => {
  const params = new HttpParams().set('id', id);
  return this.http.delete(this.baseUrl + '/delete', { params });
}

updateMaterialPurchaseMastersStatus = (data) => {
  return this.http.put(this.baseUrl + '/status', data);
}

}
