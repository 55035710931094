export class Customer {
  _id: string;
  customerName: string;
  customerCode: number;
  password: string;
  mobile: number;
  mailId: string;
  GSTIN: string;
  panNo: string;
  address: string;
  locality: string;
  pinCode: number;
  district: string;
  city: string;
  country: string;
  state: string;
  status: string;
  createdBy: string;
  modifiedBy: string;
  type: string;
}
