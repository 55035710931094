import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReworkService {
  public baseUrl: string = environment.apiBaseUrl + '/rework';

  constructor(public httpClient: HttpClient) { }

  saveRework(parts) {
    return this.httpClient.post(this.baseUrl + '/saveRework', parts);
  }

  saveReworkAssemblyQC(parts) {
    return this.httpClient.post(this.baseUrl + '/saveReworkAssemblyQC', parts);
  }

  saveReworkRODYard(parts) {
    return this.httpClient.post(this.baseUrl + '/saveReworkRODYard', parts);
  }

  saveBORework(parts) {
    return this.httpClient.post(this.baseUrl + '/saveBORework', parts);
  }

  getBoughtOutRework(data){
    return this.httpClient.put(this.baseUrl + '/BOrework', data);
  }

  getSumQuantityForRework(data){
    return this.httpClient.put(this.baseUrl + '/sumQuantity', data);
  }

  updateReworkGateOut(data){
    return this.httpClient.put(this.baseUrl + '/updateReworkBO', data);
  }

  getReworkGateInPending(data){
    return this.httpClient.put(this.baseUrl + '/reworkPending', data);
  }

  updateReworkGateIn(data){
    return this.httpClient.patch(this.baseUrl + '/updateRework', data);
  }

  updateReworkReturn(data){
    return this.httpClient.patch(this.baseUrl + '/updateReworkReturn', data);

  }

  getGateOutReports(data){
    return this.httpClient.post(this.baseUrl+'/searchGateOutss',data)
  }
  getAllBoughtOutRework(data){
    return this.httpClient.put(this.baseUrl+'/getAllBOs',data)
  }
  getAllBOVendor(data){
    return this.httpClient.put(this.baseUrl+'/getAllBOVendor',data)
  }
}
