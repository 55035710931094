import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class CompanyDetailsService {
  public baseUrl = environment.apiBaseUrl + "/companyDetails";
  constructor(private http: HttpClient) {}

  getAllCompanyDetail() {
    return this.http.get(this.baseUrl + "/");
  }
  saveAllCompanyDetails(data) {
    return this.http.post(this.baseUrl + "/", data);
  }
  updateAllCompanyDetails(data) {
    return this.http.put(this.baseUrl + "/", data);
  }
}
