export class MaterialUsageTypeMaster {
    _id: string;
    materialUsageId: number;
    materialUsageType: string;
    status: string;
    isDeleted: boolean;
    statusDescription: string;
    activeStatusId: string;
    createdBy: string;
    modifiedBy: string;
}

     