import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PumpPackingTypeMaster } from '../model/pump-packing-type-master';

@Injectable({
  providedIn: 'root'
})
export class PumpPackingTypeMasterService {

  public baseUrl: string = environment.apiBaseUrl + '/pumpPackingType';
  constructor(public http: HttpClient) { }

  getAllPackingTypeMaster = () => {
    return this.http.get(this.baseUrl + '/getAll');
  }

  getActivePackingTypeMaster = () => {
    return this.http.get(this.baseUrl + '/active');
  }

  savePackingTypeMaster = (pumpPacking: PumpPackingTypeMaster) => {
    return this.http.post(this.baseUrl + '/save', pumpPacking);
  }

  updatePackingTypeMaster = (pumpPacking: PumpPackingTypeMaster) => {
    return this.http.patch(this.baseUrl + '/update', pumpPacking);
  }

  deletePackingTypeMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete' ,{params});
  }

  updatePackingTypeStatus = (pumpPacking: PumpPackingTypeMaster) => {
    return this.http.put(this.baseUrl + '/status', pumpPacking);
  }
}
