import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DesignMasterService {
  public baseUrl = environment.apiBaseUrl + '/designMasters';
  constructor(private http: HttpClient) {}

  getAllDesignMaster = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  getActiveDesignMaster = () => {
    return this.http.get(this.baseUrl + '/active');
  };

  saveDesignMaster = (design) => {
    return this.http.post(this.baseUrl + '/save', design);
  };

  updateDesignMaster = (design) => {
    return this.http.patch(this.baseUrl + '/update', design);
  };

  deleteDesignMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };

  updateDesignMasterStatus = (data) => {
    return this.http.put(this.baseUrl + '/status', data);
  };

  getDesignMasterByPumpModel = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.get(this.baseUrl + '/byPumpModel', { params });
  };

  getDesignMasterByOnPumpModelWithArray = (id) => {
    return this.http.put(this.baseUrl + '/byPumpModelWithArray', id);
  };

  getMaterialGradeUsingPumpAndPartNo(designPartDetails: any) {
    return this.http.patch(
      this.baseUrl + '/MaterialGradeUsingPumpModelPartName/',
      designPartDetails
    );
  }
  searchDesignMaster(data: any) {
    return this.http.put(this.baseUrl + '/searchDesignMaster', data);
  }
  searchDesignMasterByDesign(data) {
    return this.http.put(this.baseUrl + '/searchDesignMasterByDesign', data);
  }
}
