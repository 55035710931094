import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DwpAccessories } from '../model/dwp-accessories';

@Injectable({
  providedIn: 'root',
})
export class DwpAccessoriesMasterService {
  public baseURL: string = environment.apiBaseUrl + '/dwpAccessories';
  public accessoriesmaster: DwpAccessories[];

  constructor(private http: HttpClient) {}

  getAllLubricationMaster = () => {
    return this.http.get(this.baseURL + '/getAll');
  };

  saveAccessoriesMaster = (accessoriesmaster: DwpAccessories) => {
    console.log(accessoriesmaster);

    return this.http.post(this.baseURL + '/save', accessoriesmaster);
  };

  updateLubricationMaster = (accessoriesmaster: DwpAccessories) => {
    return this.http.patch(this.baseURL + '/update', accessoriesmaster);
  };

  getActiveLubricationMaster = () => {
    return this.http.get(this.baseURL + '/active');
  };

  deleteLubricationMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseURL + '/delete', { params });
  };

  updateLubricationMasterStatus = (accessoriesmaster: DwpAccessories) => {
    return this.http.put(this.baseURL + '/status', accessoriesmaster);
  };
}
