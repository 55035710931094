export class TrackingPartsDetails {
  _id: string;
  stockNo: number;
  pumpModel: string;
  pumpModelId: any;
  partName: string;
  partNameId: any;
  internalPartCode: string;
  materialCode: string;
  indentNumber: any;
  subContractVendorName: string;
  subContractDcNo: number;
  dcNumber: number;
  fromDate: string;
  toDate: string;
  currentStatus: string;
}
