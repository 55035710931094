import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  public baseUrl: string = environment.apiBaseUrl + '/stock';

  constructor(public httpClient: HttpClient) { }

  getAllPendingStock() {
    return this.httpClient.get(this.baseUrl + '/getAll');
  }


  saveBOdetails(part) {
    return this.httpClient.post(this.baseUrl + '/saveStore', part);
  }

  getStockByInternalPartCode(data) {
    return this.httpClient.put(this.baseUrl + '/getStockByInternalPartCode',data);
  }

  getStockDetailsInStore(part) {
    return this.httpClient.put(this.baseUrl +'/getStockDetails', part);
  }

  getAsseblyStock(part) {
    return this.httpClient.put(this.baseUrl +'/getAsseblyStock', part);
  }

  getStockDetailsBO(part) {
    return this.httpClient.put(this.baseUrl + '/getStockDetailsBO', part);
  }

  saveAsseblyStock(part) {
    return this.httpClient.post(this.baseUrl + '/saveAsseblyStock', part);
  }

  updateAsseblyStock(part) {
    return this.httpClient.put(this.baseUrl + '/updateAsseblyStock', part);
  }

  searchStoreReport(part){
    return this.httpClient.put(this.baseUrl + '/search', part);
  }


getTrackingPartReport(data) {
  return this.httpClient.put(this.baseUrl + '/getTrackingPartReport', data);
}

getAllMaterialStock(data){
  return this.httpClient.put(this.baseUrl+'/materialStock',data)
}
findMaterial(data){
  return this.httpClient.put(this.baseUrl+'/findMaterial',data)
}
getStockByPartName(data){
  return this.httpClient.post(this.baseUrl+'/getStockByPartName',data)
}
}
