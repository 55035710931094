import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Material } from '../model/material';

@Injectable({
  providedIn: 'root'
})
export class MaterialMasterService {
  public baseURL: string =  environment.apiBaseUrl + '/materialMaster';
  constructor(private http: HttpClient) { }

  saveMachineOperationMaster = (material: Material) => {
    return this.http.post(this.baseURL + '/save', material);
  }
  getAllMaterialPurchaseMasters() {
    return this.http.get(this.baseURL + '/getAll');
}

updateMaterial = (material: Material) => {
  return this.http.patch(this.baseURL + '/updates', material);
}
deleteMaterialPurchaseMasters(id){
  const params = new HttpParams().set('id', id);
  return this.http.delete(this.baseURL + '/delete', { params });
}
updateMaterialPurchaseMasters(purchasePdfMaster) {
  return this.http.patch(this.baseURL + '/update', purchasePdfMaster);
}
getMaterialName(data){
  return this.http.put(this.baseURL+'/getName',data)
}
getMaterialDetails(data){
  return this.http.put(this.baseURL+'/getMaterialDetails',data)
}
pdateMaterialPurchaseMastersStatus = (data) => {
  return this.http.put(this.baseURL + '/status', data);
}

}
