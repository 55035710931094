import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderReportService {

  public baseUrl = environment.apiBaseUrl + '/orderReport';
  constructor(private http: HttpClient) { }

  // getAllPendingOrders() {
  //   return this.http.get(this.baseUrl + '/pending');
  // }

  getAllpendingPumps() {
    return this.http.get(this.baseUrl + '/pendingPumps');
  }

  getAllpendingPart() {
    return this.http.get(this.baseUrl + '/pendingPart');
  }

  getAllpendingAll(){
    return this.http.get(this.baseUrl+'/getPendingAll')
  }
  searchPendingOrderResult(pendingOrder) {
    return this.http.post(this.baseUrl, pendingOrder);
  }

  getInternalWOBasedOnCustomerPO(customerPO: string) {
    return this.http.get(this.baseUrl + '/getInternalWOBasedOnCustomerPO/' + customerPO);
  }

  getCustomerPOAndInternalWODetails(customerName: string) {
    return this.http.get(this.baseUrl + '/getCustomerDetailsByCustomerName/' + customerName);
  }

  getAllCompletePart() {
    return this.http.get(this.baseUrl + '/completePart');
  }
  getAllComplete() {
    return this.http.get(this.baseUrl + '/complete');
  }


  getAllCompletePump() {
    return this.http.get(this.baseUrl + '/completePump');
  }

   searchCompleteOrderResult(pendingOrder) {
    return this.http.post(this.baseUrl + '/completed', pendingOrder);
  }

  // getAllCompletedOrder() {
  //   return this.http.get(this.baseUrl);
  // }

  getCustomerPOBasedOnInternalWO(internalWO: string) {
    return this.http.get(this.baseUrl + '/getCustomerPOBasedOnInternalWO/' + internalWO);
  }

  updateDispatched(data: any) {
    return this.http.put(this.baseUrl+'/updateDispatch', data);
  }

  updateDispatchedForBoughtOut(data: any) {
    return this.http.put(this.baseUrl + '/updateDispatchedBO', data);
  }
  getAllCancelOrder(data){
    return this.http.put(this.baseUrl+"/getAllCancelOrders",data)
  }
  searchCancelOrder(data){
    return this.http.put(this.baseUrl+'/searchCancelOrders',data)
  }
  searchComplete(data){
    return this.http.put(this.baseUrl+'/completeOrderData',data)
  }
  updateDispatchedPump(data){
    return this.http.put(this.baseUrl+'/updateDispatchPump',data)
  }

}
