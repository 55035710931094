import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent implements OnInit {
  public remark;
  constructor(public dialogRef: MatDialogRef<RemarksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data.remark !== undefined) {
      this.remark = this.data.remark;
    }
  }

  confirm(): void {
    this.dialogRef.close(this.remark);
  }

  cancel(): void {
    this.dialogRef.close(1);
  }

}
