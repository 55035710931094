import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public baseUrl: string = environment.apiBaseUrl + '/users';
  constructor(public http: HttpClient) {}

  getAllUsers = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  getActiveUser = () => {
    return this.http.get(this.baseUrl + '/acitve');
  };

  saveUser = (user: User) => {
    return this.http.post(this.baseUrl + '/save', user);
  };

  updateUser = (user: User) => {
    return this.http.patch(this.baseUrl + '/update', user);
  };

  deleteUser = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };

  updateUserStatus = (role: User) => {
    return this.http.put(this.baseUrl + '/status', role);
  };
  getOneUser = (data) => {
    return this.http.put(this.baseUrl + '/getOneUser', data);
  };
}
