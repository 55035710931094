export class Store {
  _id: string;
  pumpModelId: any;
  pumpModel: string;
  partNameId: any;
  partName: any;
  materialId: string;
  materialGradeId: any;
  internalPartCode: string;
  internalPartCodeId: string;
  document: string;
  toDate: string;
  bulkCheck: number;
  fromDate: string;
  status: any;
  indentNumber: number;
  materialUsage: string;
  scanInTime: string;
  createdOn: string;
  stockNo: number;
  rsPerKG: number;
  quantity: number;
  amount: number;
  partType: string;
  mainPartName: string;
  mainPartCode: string;
}
