export class PackingTypeMaster {
    _id: string;
    packingType: string;
    // subType: string;
    status: number;
    // statusDescription: string;
    // activeStatusId: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
}
