import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CategoryMaster } from '../model/category-master';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryMasterService {

  public baseURL: string =  environment.apiBaseUrl + '/categoryMasters';
  public categoryMaster: CategoryMaster[];

  constructor(private http: HttpClient) { }
  getAllCategoryMaster = () =>  {
    return this.http.get(this.baseURL + '/getAll');
  }

  saveCategoryMaster = (categoryMaster: CategoryMaster) => {
    return this.http.post(this.baseURL + '/save', categoryMaster);
  }

  updateCategoryMaster = (categoryMaster: CategoryMaster) => {
    return this.http.patch(this.baseURL + '/update', categoryMaster);
  }

  getActiveCategoryMaster = () => {
    return this.http.get(this.baseURL + '/active');
  }

  deleteCategoryMaster  = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseURL + '/delete', { params });
  }

  updateCategoryMasterStatus = (categoryMaster: CategoryMaster) => {
    return this.http.put(this.baseURL + '/status', categoryMaster);
  }
}
