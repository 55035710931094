import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MainPartMaster } from '../model/main-part-master';

@Injectable({
  providedIn: 'root',
})
export class MainPartMasterService {
  public baseUrl = environment.apiBaseUrl + '/mainPartMasters';
  constructor(private http: HttpClient) {}

  savePartMaster = (partMaster: MainPartMaster) => {
    return this.http.post(this.baseUrl + '/save', partMaster);
  };
  getAllPartMaster = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  updatePartMaster = (partMaster: MainPartMaster) => {
    return this.http.patch(this.baseUrl + '/update', partMaster);
  };
  updatePartMasterStatus = (partMaster: MainPartMaster) => {
    return this.http.put(this.baseUrl + '/status', partMaster);
  };
  deletePartMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };
}
