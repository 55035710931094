import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BoughtOutAssemblyService {

  public baseUrl = environment.apiBaseUrl + '/boughtOutAssembly';
  constructor(private http: HttpClient) { }

  getAllBoughtOutAssembly() {
    return this.http.get(this.baseUrl);
  }

  getBoughtOutAssembly(data) {
    return this.http.put(this.baseUrl, data);
  }
  saveBoughtOutAssembly(boughtOut: any): Observable<any> {
    return this.http.post(this.baseUrl, boughtOut);
  }

  updateBoughtOutAssembly(boughtOut: any): Observable<any> {
    return this.http.patch(this.baseUrl, boughtOut);
  }

  getAssembleDC(boughtOut) {
    return this.http.put(this.baseUrl + '/dc', boughtOut,{ responseType: 'arraybuffer' });
  }

  getDispatchPendingDetails(status) {
    console.log('status',status);
    return this.http.patch(this.baseUrl+ '/dispatchPending' , status);
  }

  updateDispatchPending(rework: any) {
    return this.http.patch(this.baseUrl+ '/updateDispatchPending' , rework);
  }


}
