import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FabricationMaster } from '../model/fabrication-master';

@Injectable({
  providedIn: 'root',
})
export class FabricationMasterService {
  public baseUrl = environment.apiBaseUrl + '/fabricationMasters';
  constructor(private http: HttpClient) {}

  getAllBoughtOutMaster = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  getActiveBoughtOutMaster = () => {
    return this.http.get(this.baseUrl + '/active');
  };

  saveBoughtOut = (fabrication: FabricationMaster) => {
    return this.http.post(this.baseUrl + '/save', fabrication);
  };

  updateBoughtOut = (fabrication: FabricationMaster) => {
    return this.http.patch(this.baseUrl + '/update', fabrication);
  };

  deleteBoughtOutMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };

  updateBoughtOutMasterStatus = (data) => {
    return this.http.put(this.baseUrl + '/status', data);
  };

  getFabricationByPartIdSub = (data) => {
    return this.http.put(this.baseUrl + '/getFabricationByPartSub', data);
  };

  getBoughtOutByPartId = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.get(this.baseUrl + '/getBoughtByPart', { params });
  };

  getBoughtOutMasterDetailsByInternalPartCode(internalPartCode) {
    return this.http.put(
      this.baseUrl + '/boughtOutByPartCode',
      internalPartCode
    );
  }
  getFabricationMaterial(data) {
    return this.http.put(this.baseUrl + '/getFabricationMaterial', data);
  }
}
