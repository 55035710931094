import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitMasterService {

  public baseUrl = environment.apiBaseUrl + '/unit';
  constructor(private http: HttpClient) { }

  getAllUnitMaster(){
    return this.http.get(this.baseUrl+"/getAll")
  }

  saveUnitMaster(data){
    return this.http.post(this.baseUrl+"/save",data)
  }

  deleteUnitMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  }

  statusUnitMaster(data){
    return this.http.put(this.baseUrl+"/status",data);
  }
}
