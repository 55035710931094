import { OrderPartDetails } from './order-part-details';

export class Order {
    _id: string;
    id: string;
    orderDate: string;
    customerName: string;
    customerId: any;
    customerPO: string;
    customerCode:number;
    customerPODate: string;
    internalWO: number;
    internalWO_QR : string;
    deliveryDate: string;
    paymentType: any;
    advancePercentage: number;
    afterDelivery: number;
    orderType: string;
    moc: number;
    consigneeName :string;
    orderPartDetails: OrderPartDetails[];
    status: string;
    orderStatus: string;
    createdBy: string;
    modifiedBy: string;
    quantity: number;
    remarks: string;
}
