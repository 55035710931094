import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PuchaseMaterialService {
  // getAllPendingData

  public baseUrl: string = environment.apiBaseUrl + '/purchaseMaterial';
  constructor(private httpClient: HttpClient) {}

  getVendorWO(vendorId: string, vendorCode: string) {
    const params = new HttpParams()
      .set('vendorId', vendorId)
      .set('vendorCode', vendorCode);
    return this.httpClient.get(this.baseUrl + '/getVendorWO', { params });
  }
  savePurchaseMaterial(data) {
    return this.httpClient.post(this.baseUrl + '/save', data, {
      responseType: 'arraybuffer',
    });
  }
  getAllGate(data) {
    return this.httpClient.put(this.baseUrl + '/getAllDate', data);
  }
  updatePruchase(data) {
    return this.httpClient.post(this.baseUrl + '/update', data);
  }
  SaveMaterialDistripution(data) {
    return this.httpClient.post(this.baseUrl + '/saveAccessories', data);
  }

  getAllAccessories() {
    return this.httpClient.get(this.baseUrl + '/getAllAccessories');
  }

  searchMaterial(data) {
    return this.httpClient.put(this.baseUrl + '/searchMaterial', data);
  }
  getAllPendingData(data) {
    return this.httpClient.put(this.baseUrl + '/getAllPendingData', data);
  }
  getAllCompleted(data) {
    return this.httpClient.put(this.baseUrl + '/getAllCompleted', data);
  }
  searchDetails(data) {
    return this.httpClient.put(this.baseUrl + '/searchDetails', data);
  }
  getAllVendors(data) {
    return this.httpClient.put(this.baseUrl + '/getAllDate', data);
  }
  saveMaterialByVendor(data) {
    return this.httpClient.put(this.baseUrl + '/updatePurchaseMaterial', data, {
      responseType: 'arraybuffer',
    });
  }
  deletePuchase(data) {
    return this.httpClient.put(this.baseUrl + '/deletePurchaseMaterial', data);
  }
  searchPurchaseDetail(data) {
    return this.httpClient.put(this.baseUrl + '/searchPurchaseDetails', data);
  }
  getAllPurchase() {
    return this.httpClient.get(this.baseUrl + '/getAllPurchase');
  }
  getReprint(data) {
    return this.httpClient.put(this.baseUrl + '/getReprint', data, {
      responseType: 'arraybuffer',
    });
  }
  updateSpecfication(data) {
    return this.httpClient.put(this.baseUrl + '/updateSpecfication', data);
  }
}
