import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {VendorReport } from 'src/app/purchase/model/vendor-report'

@Injectable({
  providedIn: 'root'
})
export class VendorReportService {

  public baseUrl: string = environment.apiBaseUrl + '/vendorReport';
  constructor(private httpClient: HttpClient) { }

  vendorPendingReportForPurchase = (data) => {
    return this.httpClient.patch(this.baseUrl + '/pendingReport', data);
  }

  getAllPendingVendorReport(partType) {
    const params = new HttpParams().set('partType', partType);
    return this.httpClient.get(this.baseUrl + '/vendorPending/' , { params });
  };
  
  searchVendorPendingReport(vendorReport: VendorReport) {
    return this.httpClient.post(this.baseUrl + '/vendorPending', vendorReport);
    }
  
    getAllCompltedVendorReport(partType) {
      const params = new HttpParams().set('partType', partType);
      return this.httpClient.get(this.baseUrl + '/vendorCompleted/',  { params });
    }
  
    searchVendorCompletedReport(vendorReport: VendorReport) {
      return this.httpClient.post(this.baseUrl + '/vendorCompleted', vendorReport);
    }

    getAllVendorCastingReport(partType) {
      return this.httpClient.patch(this.baseUrl + '/vendorCasting', partType)
    }
  
    searchVendorCastingReport(data) {
      return this.httpClient.post(this.baseUrl + '/searchVendorCasting', data) 
    }
    getVendorRatesBasedOnVendorCodeAndVendorPartCode(vendorData) {
      return this.httpClient.post(this.baseUrl + '/getRateBasedOnVendorCodeAndRPartCode', vendorData);
    }

    getRateBasedOnVendorPartCode(datas){
      return this.httpClient.post(this.baseUrl + '/getRateByVendorCode', datas);
    }
}
