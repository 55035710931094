export class CommonParts {
  partType: string;
  vendorName: string;
  customerName: string;
  supplierName: string;
  dcType: string;
  materialName: string;
  moc: string;
  option: any;
  dcNo: any;
  status: string;
  remarks: string;
  invoiceNo: string;
  invoiceDate: string;
  commonDCNo: string;
  transaction: number;
}
