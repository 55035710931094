export class Calibration {
  _id: any;
  vendorId: any;
  vendorName: string;
  outId: number;
  address: any;
  outDate: string;
  processing: string;
  purchaseId: any;
  categoryName: string;
  categoryId: any;
  itemMasterId: any;
  itemName: string;
  itemId: string;
  calibrationDate: string;
  calibrationExpDate: string;
  calibrationCertificateNumber: string;
  reason: string;
  returnDate: string;
  otherAmount: number;
  serviceAmount: number;
  option: any;
  spareAmount: number;
  making: string;
  document: string;
  from: string;
  fromDate: string;
  toDate: string;
  serialNumber1: string;
  serialNumber2: string;
  calibrationAmount: string;
  status: string;
  receivedDate: string;
  invoiceNo: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  remarks: string;
  quantity: Number;
}
