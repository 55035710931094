import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public baseUrl: string = environment.apiBaseUrl + '/store';

  constructor(public httpClient: HttpClient) {}

  saveStoreByStockNo(storeIn) {
    return this.httpClient.post(this.baseUrl + '/save', storeIn);
  }

  updateStoreByStockNo(storeOut) {
    return this.httpClient.post(this.baseUrl + '/update', storeOut);
  }
  searchStoreReport(data) {
    return this.httpClient.post(this.baseUrl + '/storeReport', data);
  }

  getAllStoreReport() {
    return this.httpClient.get(this.baseUrl + '/getAllStore');
  }

  searchStockReport(data) {
    return this.httpClient.post(this.baseUrl + '/stockReport', data);
  }
  saveStore(data) {
    return this.httpClient.post(this.baseUrl + '/', data);
  }
  getStockReport() {
    return this.httpClient.get(this.baseUrl + '/getStockReport');
  }
  findStoreDetails(data) {
    return this.httpClient.put(this.baseUrl + '/findStoreDetails', data);
  }
}
