import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubCategoryFabricationMaster } from '../model/sub-category-fabrication-master';

@Injectable({
  providedIn: 'root',
})
export class SubCategoryFabricationMasterService {
  public baseUrl = environment.apiBaseUrl + '/subCategoryFabrications';
  constructor(private http: HttpClient) {}

  getAllSubCategoryMaster = () => {
    return this.http.get(this.baseUrl + '/getAll');
  };

  getActiveSubCategoryMaster = () => {
    return this.http.get(this.baseUrl + '/active');
  };

  saveSubCategory = (subcategory: SubCategoryFabricationMaster) => {
    return this.http.post(this.baseUrl + '/save', subcategory);
  };

  updateSubCategory = (subcategory: SubCategoryFabricationMaster) => {
    return this.http.patch(this.baseUrl + '/update', subcategory);
  };

  deleteSubCategoryMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  };

  updateSubCategoryMasterStatus = (data) => {
    return this.http.put(this.baseUrl + '/status', data);
  };

  getSubCategoryByPartNameId(data) {
    return this.http.put(this.baseUrl + '/byPartId', data);
  }
  getSubCategoryByPartName(data) {
    return this.http.put(this.baseUrl + '/bygetPartName', data);
  }
}
