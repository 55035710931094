<div *ngIf="data.value === 1">
  <div mat-dialog-title>
    <h4>Remarks</h4>
  </div>
  <div mat-dialog-content>
    <form #exampleForm="ngForm">
      <mat-form-field class="example-full-width">
        <textarea
          matInput
          name="remark"
          placeholder="Remark"
          [minlength]="10"
          [(ngModel)]="remark"
          autocomplete="off"
          required
        ></textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!exampleForm.valid"
      (click)="confirm()"
    >
      Confirm
    </button>
    <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
  </div>
</div>
