import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PartcodeMaster } from '../model/partcode-master';

@Injectable({
  providedIn: 'root'
})
export class PartcodeMasterService {



  public baseURL: string =  environment.apiBaseUrl + '/partCodeMasters';
  public partCodeMaster :PartcodeMaster[];

  constructor(private http: HttpClient) { }

  getAllLubricationMaster = () =>  {
    return this.http.get(this.baseURL + '/getAll');
  }

  saveAccessoriesMaster = (partCodeMaster: PartcodeMaster) => {
    return this.http.post(this.baseURL + '/save', partCodeMaster);
  }

  updateLubricationMaster = (partCodeMaster: PartcodeMaster) => {
    return this.http.patch(this.baseURL + '/update', partCodeMaster);
  }

  getActiveLubricationMaster = () => {
    return this.http.get(this.baseURL + '/active');
  }

  deleteLubricationMaster  = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseURL + '/delete', { params });
  }

  updateLubricationMasterStatus = (partCodeMaster: PartcodeMaster) => {
    return this.http.put(this.baseURL + '/status', partCodeMaster);
  }
}
