import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoleMaster } from '../model/role-master';

@Injectable({
  providedIn: 'root'
})
export class RoleMasterService {

  public baseUrl: string = environment.apiBaseUrl + '/roles';
  public roleMaster: RoleMaster[];
  public roles = [];

  constructor(public http: HttpClient) { }

  getAllRoleMasters = () => {
    return this.http.get(this.baseUrl + '/getAll');
  }

  saveRoleMaster = (role: RoleMaster) => {
    return this.http.post(this.baseUrl + '/save', role);
  }

  updateRoleMaster = (role: RoleMaster) => {
    return this.http.patch(this.baseUrl + '/update', role);
  }

  getActiveRoleMasters = () => {
    return this.http.get(this.baseUrl + '/active');
  }

  deleteRoleMaster = (id) => {
    const params = new HttpParams().set('id', id);
    return this.http.delete(this.baseUrl + '/delete', { params });
  }

  updateRoleStatus = (role: RoleMaster) => {
    return this.http.put(this.baseUrl + '/status', role);
  }
}
